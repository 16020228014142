<template>
	<div class="page" style="position: relative;" >
		<div class="div" style="position: relative;">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item style="cursor: pointer !important;"><span style="color: #AB54DB;font-weight: bold;" @click="goback()">{{lastPage=='lastPage'?'上一页':'帮助中心'}}</span></el-breadcrumb-item>
        <el-breadcrumb-item style="color: #72777E">新手教程</el-breadcrumb-item>
      </el-breadcrumb>
			<div class="span-title">
				<div class="title-bg-div" style="" v-for="(item, index) in titleList">
					<div :class="item.checked == true ? 'title-bg-click' : 'title-bg'" style="display: inline-block; border-radius: 10px; width: 100%;cursor: pointer" @click="changeType(index)">
						<span style="font-size: 12px; padding: 10px 0 10px 10px; display: inline-block; width: 100%;">{{isMobile?(index+1)+'.':'第'+(index+1)+'步：'}}<span style="margin-left: 5px; font-size: 12px">{{ item.span}}</span></span>
					</div>
				</div>
			</div>
			<div style="background: #464653; height: 1px; margin: 40px 0 30px; opacity: 0.2"></div>
			<div id="tutorialId" style="padding-bottom: 60px;">
				<div v-if="pageType == 0" style="">
					<span style="display: block; font-size: 16px;">{{$t('tutorial.Register_account')}}</span>
					<span style="display: block;margin-top: 10px;">{{$t('tutorial.User_registration')}}</span>
					<span style="display: block; margin-left: 20px;">{{$t('tutorial.regist_tip')}}</span>
					<img style="width: 300px; height: 361px; margin: 10px auto 0; display: block;" src="../../assets/img/help/open_tutorial/1.png" alt=""/>
					<span style="display: block; margin-top: 20px;">{{$t('tutorial.User_login')}}</span>
					<span style="display: block; margin-left: 20px;">{{$t('tutorial.login_tip')}}</span>
					<img style="width: 300px; height: 311.5px; margin: 10px auto 0; display: block;" src="../../assets/img/help/open_tutorial/2.png" alt=""/>
					<span style="font-size: 14px; width: fit-content; padding: 5px 20px; margin: 30px auto 0; border: 1px #999999 solid; border-radius: 8px; display: block;cursor: pointer" @click="next(0)">{{$t('tutorial.next')}}</span>
					<div style="margin-top: 40px;">
						<span style="display: inline-block; font-weight: 500;font-size: 12px;color: #1E2329;vertical-align: middle;">** {{$t('tutorial.goto_opentutorial_tip1')}}</span>
						<span style="display: inline-block; font-size: 12px;color: #1E2329;vertical-align: middle; border-radius: 50%; border: #1E2329 1px solid; width: 13px; height: 13px; text-align: center;line-height: 13px;margin: 0 3px">?</span>
						<span style="display: inline-block;font-size: 12px;font-weight: 500;color: #BF5FC1;vertical-align: middle;cursor: pointer" @click="gotoCommonProblem()">{{$t('tutorial.common_problem')}}{{' '}}</span>
						<span style="display: inline-block;font-weight: 500;font-size: 12px;color: #1E2329;vertical-align: middle;">{{applicationLocale=='zh-Hans'?'，':','}}{{$t('tutorial.goto_opentutorial_tip2')}}</span>
					</div>
				</div>

				<div v-if="pageType == 1">
					<span style="display: block; font-size: 16px;">{{$t('tutorial.Bind_Exchange_API')}}</span>
					<span style="display: block; margin-top: 10px;">{{$t('tutorial.bind_api_tip1')}}</span>
					<img style="width: 300px; height: 124px; margin: 10px auto 0; display: block;" src="../../assets/img/help/open_tutorial/3.png" alt=""/>
					<img style="width: 300px; height: 188.5px; margin: 10px auto 0; display: block;" src="../../assets/img/help/open_tutorial/4.png" alt=""/>
					<!-- <img style="width: 300px; height: 98px; margin: 10px auto 0; display: block;" src="../../assets/img/help/open_tutorial/4_1.png" alt=""/> -->
					<span style="display: block; margin-top: 20px;">{{$t('tutorial.bind_api_tip2')}}</span>
					<span style="display: block; margin-top: 10px;margin-left: 10px;">{{$t('tutorial.okx_api_tip')}}</span>
					<img style="width: 300px; height: 238px; margin: 10px auto 0; display: block;" src="../../assets/img/help/open_tutorial/2_2_3.png" alt=""/>
					<span style="display: block; margin-top: 10px;margin-left: 10px;">{{$t('tutorial.binance_api_tip')}}</span>
					<span style="display: block; margin-top: 10px;margin-left: 20px;">{{$t('tutorial.binance_api_tip1')}}</span>
					<img style="width: 300px; height: 190px; margin: 10px auto 0; display: block;" src="../../assets/img/help/open_tutorial/2_3_1.png" alt=""/>
					<span style="display: block; margin-top: 10px;margin-left: 20px;">{{$t('tutorial.binance_api_tip2')}}</span>
					<img style="width: 300px; height: 331.5px; margin: 10px auto 0; display: block;" src="../../assets/img/help/open_tutorial/2_3_2.png" alt=""/>
					<img style="width: 300px; height: 235.5px; margin: 10px auto 0; display: block;" src="../../assets/img/help/open_tutorial/2_3_3.png" alt=""/>
					<span style="display: block; margin-top: 10px;margin-left: 20px;">{{$t('tutorial.binance_api_tip3')}}</span>
					<img style="width: 300px; height: 242.5px; margin: 10px auto 0; display: block;" src="../../assets/img/help/open_tutorial/2_3_4.png" alt=""/>
					<img style="width: 300px; height: 170.5px; margin: 10px auto 0; display: block;" src="../../assets/img/help/open_tutorial/2_3_5.png" alt=""/>
					<!-- <span style="display: block; margin-top: 20px;margin-left: 10px;">{{$t('tutorial.gate_api_tip')}}</span>
					<img style="width: 300px; height: 93px; margin: 10px auto 0; display: block;" src="../../assets/img/help/open_tutorial/2_2_1.png" alt=""/>
					<img style="width: 300px; height: 270px; margin: 10px auto 0; display: block;" src="../../assets/img/help/open_tutorial/2_2_2.png" alt=""/> -->
					<span style="display: block; margin-top: 20px;">{{$t('tutorial.bind_api_tip3')}}</span>
					<img style="width: 300px; height: 188.5px; margin: 10px auto 0; display: block;" src="../../assets/img/help/open_tutorial/2_2_4.png" alt=""/>
					<img style="width: 300px; height: 126px; margin: 10px auto 0; display: block;" src="../../assets/img/help/open_tutorial/5.png" alt=""/>
					<span style="font-size: 14px; width: fit-content; padding: 5px 20px; margin: 30px auto 0; border: 1px #999999 solid; border-radius: 8px; display: block;cursor: pointer" @click="next(1)">{{$t('tutorial.next')}}</span>
					<div style="margin-top: 40px;">
						<span style="display: inline-block; font-weight: 500;font-size: 12px;color: #1E2329;vertical-align: middle;">** {{$t('tutorial.goto_opentutorial_tip1')}}</span>
						<span style="display: inline-block; font-size: 12px;color: #1E2329;vertical-align: middle; border-radius: 50%; border: #1E2329 1px solid; width: 13px; height: 13px; text-align: center;line-height: 13px;margin: 0 3px">?</span>
						<span style="display: inline-block;font-size: 12px;font-weight: 500;color: #BF5FC1;vertical-align: middle;cursor: pointer" @click="gotoCommonProblem()">{{$t('tutorial.common_problem')}}{{' '}}</span>
						<span style="display: inline-block;font-weight: 500;font-size: 12px;color: #1E2329;vertical-align: middle;">{{applicationLocale=='zh-Hans'?'，':','}}{{$t('tutorial.goto_opentutorial_tip2')}}</span>
					</div>
				</div>

				<div v-if="pageType == 2">
					<span style="display: block; font-size: 16px;">{{$t('tutorial.okx_open_account_contract')}}</span>
					<span style="display: block; margin-top: 10px;">{{$t('tutorial.okx_open_account_contract1')}}</span>
					<img style="width: 300px; height: 124px; margin: 10px auto 0; display: block;" src="../../assets/img/help/open_tutorial/6.png" alt=""/>
					<span style="display: block; margin-top: 20px;">{{$t('tutorial.okx_open_account_contract2')}}<img style="width: 20px; height: 20px;" src="../../assets/img/help/open_tutorial/menu.png" alt=""/>{{$t('tutorial.quotation_marks')}}</span>
					<img style="width: 300px; height: 114px; margin: 10px auto 0; display: block;" src="../../assets/img/help/open_tutorial/8.png" alt=""/>
					<span style="display: block; margin-top: 20px;">{{$t('tutorial.okx_open_account_contract3')}}</span>
					<img style="width: 300px; height: 170px; margin: 10px auto 0; display: block;" src="../../assets/img/help/open_tutorial/9.png" alt=""/>
					<span style="display: block; margin-top: 20px;">{{$t('tutorial.okx_open_account_contract4')}}</span>
					<img style="width: 300px; height: 162px; margin: 10px auto 0; display: block;" src="../../assets/img/help/open_tutorial/10.png" alt=""/>
					<img style="width: 300px; height: 138px; margin: 10px auto 0; display: block;" src="../../assets/img/help/open_tutorial/11.png" alt=""/>
					<span style="display: block; margin-top: 20px;">{{$t('tutorial.okx_open_account_contract5')}}</span>
					<img style="width: 300px; height: 240px; margin: 10px auto 0; display: block;" src="../../assets/img/help/open_tutorial/3_5_1.png" alt=""/>
					<img style="width: 300px; height: 126px; margin: 10px auto 0; display: block;" src="../../assets/img/help/open_tutorial/3_5_2.png" alt=""/>
					<span style="font-size: 14px; width: fit-content; padding: 5px 20px; margin: 30px auto 0; border: 1px #999999 solid; border-radius: 8px; display: block;cursor: pointer" @click="next(2)">{{$t('tutorial.next')}}</span>
					<div style="margin-top: 40px;">
						<span style="display: inline-block; font-weight: 500;font-size: 12px;color: #1E2329;vertical-align: middle;">** {{$t('tutorial.goto_opentutorial_tip1')}}</span>
						<span style="display: inline-block; font-size: 12px;color: #1E2329;vertical-align: middle; border-radius: 50%; border: #1E2329 1px solid; width: 13px; height: 13px; text-align: center;line-height: 13px;margin: 0 3px">?</span>
						<span style="display: inline-block;font-size: 12px;font-weight: 500;color: #BF5FC1;vertical-align: middle;cursor: pointer" @click="gotoCommonProblem()">{{$t('tutorial.common_problem')}}{{' '}}</span>
						<span style="display: inline-block;font-weight: 500;font-size: 12px;color: #1E2329;vertical-align: middle;">{{applicationLocale=='zh-Hans'?'，':','}}{{$t('tutorial.goto_opentutorial_tip2')}}</span>
					</div>
				</div>

				<div v-if="pageType == 3">
					<span style="display: block; font-size: 16px;">{{$t('tutorial.recharge_fuel_fee')}}</span>
					<span style="display: block; margin-top: 10px;">{{$t('tutorial.recharge_fuel_fee1')}}</span>
					<img style="width: 300px; height: 146.5px; margin: 10px auto 0; display: block;" src="../../assets/img/help/open_tutorial/12.png" alt=""/>
					<span style="display: block; margin-top: 20px;">{{$t('tutorial.recharge_fuel_fee2')}}</span>
					<img style="width: 300px; height: 126px; margin: 10px auto 0; display: block;" src="../../assets/img/help/open_tutorial/13_1.png" alt=""/>
					<img style="width: 300px; height: 271.5px; margin: 10px auto 0; display: block;" src="../../assets/img/help/open_tutorial/13.png" alt=""/>
					<span style="display: block; margin-top: 20px;">{{$t('tutorial.recharge_fuel_fee3')}}</span>
					<span style="display: block; margin-left: 20px;">{{$t('tutorial.recharge_fuel_fee4')}}</span>
					<img style="width: 300px; height: 123px; margin: 10px auto 0; display: block;" src="../../assets/img/help/open_tutorial/14.png" alt=""/>
					<img style="width: 300px; height: 156px; margin: 10px auto 0; display: block;" src="../../assets/img/help/open_tutorial/15.png" alt=""/>
					<span style="display: block; font-size: 16px;margin-top: 30px;">{{$t('tutorial.purchase_point_card')}}</span>
					<span style="display: block; margin-top: 10px;">{{$t('tutorial.recharge_fuel_fee5')}}</span>
					<img style="width: 300px; height: 144.5px; margin: 10px auto 0; display: block;" src="../../assets/img/help/open_tutorial/3_2_1.png" alt=""/>
					<img style="width: 300px; height: 81px; margin: 10px auto 0; display: block;" src="../../assets/img/help/open_tutorial/3_2_2.png" alt=""/>
					<span style="display: block; margin-top: 20px;">{{$t('tutorial.recharge_fuel_fee6')}}</span>
					<img style="width: 300px; height: 291px; margin: 10px auto 0; display: block;" src="../../assets/img/help/open_tutorial/3_2_3.png" alt=""/>
					<span style="font-size: 14px; width: fit-content; padding: 5px 20px; margin: 30px auto 0; border: 1px #999999 solid; border-radius: 8px; display: block;cursor: pointer" @click="next(3)">{{$t('tutorial.next')}}</span>
					<div style="margin-top: 40px;">
						<span style="display: inline-block; font-weight: 500;font-size: 12px;color: #1E2329;vertical-align: middle;">** {{$t('tutorial.goto_opentutorial_tip1')}}</span>
            <span style="display: inline-block; font-size: 12px;color: #1E2329;vertical-align: middle; border-radius: 50%; border: #1E2329 1px solid; width: 13px; height: 13px; text-align: center;line-height: 13px;margin: 0 3px">?</span>
						<span style="display: inline-block;font-size: 12px;font-weight: 500;color: #BF5FC1;vertical-align: middle;cursor: pointer" @click="gotoCommonProblem()">{{$t('tutorial.common_problem')}}{{' '}}</span>
						<span style="display: inline-block;font-weight: 500;font-size: 12px;color: #1E2329;vertical-align: middle;">{{applicationLocale=='zh-Hans'?'，':','}}{{$t('tutorial.goto_opentutorial_tip2')}}</span>
					</div>
				</div>

				<div v-if="pageType == 4">
					<span style="display: block; font-size: 16px;">{{$t('tutorial.start_robot')}}</span>
					<span style="display: block; margin-top: 10px;">{{$t('tutorial.start_robot1')}}</span>
					<img style="width: 300px; height: 295px; margin: 10px auto 0; display: block;" src="../../assets/img/help/open_tutorial/16.png" alt=""/>
					<img style="width: 300px; height: 290px; margin: 10px auto 0; display: block;" src="../../assets/img/help/open_tutorial/17.png" alt=""/>
					<span style="display: block; margin-top: 20px;">{{$t('tutorial.start_robot2')}}</span>
					<span style="display: block; margin-left: 20px;">{{$t('tutorial.start_robot2_1_1')}}</span>
					<span style="display: block; margin-left: 20px;">{{$t('tutorial.start_robot2_1_2')}}</span>
					<img style="width: 300px; height: 257px; margin: 10px auto 0; display: block;" src="../../assets/img/help/open_tutorial/18.png" alt=""/>
					<span style="display: block; margin-left: 20px;">{{$t('tutorial.start_robot2_2_1')}}</span>
					<span style="display: block; margin-left: 20px;">{{$t('tutorial.start_robot2_2_2')}}<span style="color: #FF473F;">{{$t('tutorial.start_robot2_2_3')}}</span></span>
					<span style="display: block; margin-left: 20px;">{{$t('tutorial.start_robot2_2_4')}}</span>
					<span style="font-size: 14px; width: fit-content; padding: 5px 20px; margin: 30px auto 0; border: 1px #999999 solid; border-radius: 8px; display: block;cursor: pointer" @click="next(4)">{{token?$t('tutorial.create_policy'):$t('tutorial.go_login')}}</span>
					<div style="margin-top: 40px;">
						<span style="display: inline-block; font-weight: 500;font-size: 12px;color: #1E2329;vertical-align: middle;">** {{$t('tutorial.goto_opentutorial_tip1')}}</span>
						<span style="display: inline-block; font-size: 12px;color: #1E2329;vertical-align: middle; border-radius: 50%; border: #1E2329 1px solid; width: 13px; height: 13px; text-align: center;line-height: 13px;margin: 0 3px">?</span>
						<span style="display: inline-block;font-size: 12px;font-weight: 500;color: #BF5FC1;vertical-align: middle;cursor: pointer" @click="gotoCommonProblem()">{{$t('tutorial.common_problem')}}{{' '}}</span>
						<span style="display: inline-block;font-weight: 500;font-size: 12px;color: #1E2329;vertical-align: middle;">{{applicationLocale=='zh-Hans'?'，':','}}{{$t('tutorial.goto_opentutorial_tip2')}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// import {setRecommendUserTag} from "../../service/index/indexPage.js"
	export default {
		data(){
			return{
				phoneHeight: 0,
				userInfo: {},
				pageType: 0,
				token: '',
				titleList: [{checked: true, span: this.$t('tutorial.Register_account')}, {checked: false, span: this.$t('tutorial.Bind_Exchange_API')}, {checked: false, span: this.$t('tutorial.okx_open_account_contract')}, {checked: false, span: this.$t('tutorial.recharge_fuel_fee_point_card')}, {checked: false, span: this.$t('tutorial.start_robot')}],
				applicationLocale: '',
        isMobile: false,
        lastPage: ''
			}
		},

		created() {
			// this.phoneHeight = uni.getSystemInfoSync().windowHeight
      this.token = localStorage.getItem('token')
      this.lastPage = this.$route.query.lastPage
		},

    mounted() {
      if (this.$util._isMobile()){
        this.isMobile = true
      }
    },

    methods:{
			changeType(index){
				this.pageType = index
				for (var i = 0; i < this.titleList.length; i++) {
					if(index == i){
						this.titleList[i].checked = true
					} else {
						this.titleList[i].checked = false
					}
				}
			},
			gotoMessage(){

			},
			next(page){
				if(page == 0){
					this.pageType = 1
				} else if(page == 1){
					this.pageType = 2
				} else if(page == 2){
					this.pageType = 3
				} else if(page == 3){
					this.pageType = 4
				} else if(page == 4){
          // this.$router.go(-2)
          this.$router.push('../../robot')
          // for (var i = 0; i < this.$router.options.routes.length; i++) {
          //   if(this.$router.options.routes[i].path == '/help'){
          //     this.$router.options.routes.slice(i, -1)
          //   }
          // }
				}
        document.querySelector('#tutorialId').scrollIntoView({
          behavior: "smooth",
          // 定义动画过渡效果， "auto"或 "smooth" 之一。默认为 "auto"
          block: "start",
          // 定义垂直方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "start"
          inline: "nearest"
          // 定义水平方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "nearest"
        })

				for (var i = 0; i < this.titleList.length; i++) {
					if(i == page+1){
						this.titleList[i].checked = true
					} else {
						this.titleList[i].checked = false
					}

				}

			},
			gotoCommonProblem(){
        this.$router.push('../helpCommonProblem?routePath=last')
			},
      goback(){
				this.$router.go(-1)
			}
		}
	}
</script>

<style scoped>
	span{
		color: #1E2329;
		font-size: 14px;
		line-height: 22px;
	}

	.title-bg-click{
		background: #816399;
	}

  .title-bg-click span{
    color: #FFFFFF;
  }

	.title-bg{
		background: #FFFFFF;
		color: #97979D;
	}

	.span-content {
		font-size: 14px;
		color: #333333;
		float: right;
		font-weight: 500;
	}

  @media screen and (max-width: 900px){
    .div{
      padding-top: unset;
      width: 90%;
      margin: -27px auto 0;
    }

    .title-bg-div {
      display: inline-block;
      width: 48%;
    }

    .span-title{
      margin-top: 30px;
      display: block;
      justify-content: unset;
    }

    .span-title div:nth-child(2n) {
      margin-left: 4%;
    }

    .span-title div:nth-child(n+3) {
      margin-top: 12px;
    }

  }

  @media screen and (min-width: 900px) {
    .div{
      padding-top: unset;
      width: 1200px;
      margin: 50px auto 0;
    }

    .title-bg-div {
      display: inline-flex;
      width: 16%;
    }

    .span-title{
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
    }

    .span-title div:nth-child(2n) {
      margin-left: unset;
    }

    .span-title div:nth-child(n+3) {
      margin-top: unset;
    }
  }

</style>
